<template>
  <div id="project-cutting-he-histori" data-app>
    
    <!--begin::Notice-->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg svg-icon-primary">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Alerts</b> Provide contextual feedback messages for typical user
        actions with the handful of available and flexible alert messages.
        <a
          class="font-weight-bold"
          href="https://bootstrap-vue.js.org/docs/components/alert"
          target="_blank"
        >
          See documentation.
        </a>
      </div>
    </b-alert>
    <!--end::Notice-->

    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">Histori</span>
              <span class="text-muted mt-3 font-weight-bold font-size-sm">List histori input cutting he</span>
          </h3>
          <div class="card-toolbar">
              <a v-b-tooltip.hover  @click="dialogFilter = true" title="Filter" class="btn btn-primary font-weight-bolder font-size-sm mr-2">
                <span class="svg-icon svg-icon-md svg-icon-white">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Home/Book-open.svg" />
                    <!--end::Svg Icon-->
                </span>
                <span class="d-none d-sm-inline">Filter</span>                
              </a>
          </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
          <!--begin::Table-->
          <div class="table-responsive">
              <table class="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                      <tr class="text-uppercase">
                          <th class="pl-7">
                              <span class="text-primary">Berita Acara</span>
                          </th>
                          <th class="pl-7 d-none d-sm-table-cell">
                              <span>Jumlah</span>
                          </th>
                          <th class="pl-7 d-none d-md-table-cell">
                              <span>Status</span>
                          </th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody v-if="!isLoading">
                      <tr v-for="(val, key) in items" :key="key">
                          <td class="pl-0 py-5">
                              <div class="d-flex align-items-center">
                                  <div class="symbol symbol-50 flex-shrink-0 mr-4">
                                      <div class="symbol-label">
                                        <span v-if="val.peringatan" v-b-tooltip.hover :title="val.peringatan" :id="`peringatan-${key}`" class="cursor-pointer svg-icon svg-icon-lg svg-icon-danger mr-2 m-0">
                                            <!--begin::Svg Icon-->
                                            <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                                            <!--end::Svg Icon-->
                                        </span> 
                                      </div>
                                  </div>

                                  <div>
                                      <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ val.no_ba }}</a>
                                      <span class="text-muted font-weight-bold d-block font-size-sm">{{ val.tgl_afkir | dateIndonesia }}</span>
                                  </div>
                              </div>
                          </td>
                          <td class="d-none d-sm-table-cell">
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {{ val.rincian_sum_jumlah | nominal }}
                              </span>
                              <span class="text-muted font-weight-bold font-size-sm">
                                  ekor
                              </span>
                          </td>
                          <td class="d-none d-md-table-cell">
                              <b-badge :variant="val.sts.color">{{ val.sts.uraian }}</b-badge>
                          </td>
                          <td class="text-right pr-0">
                            <b-dropdown size="sm" variant="hover-primary" class="btn-light rounded">
                                <template v-slot:button-content>
                                    <span class="svg-icon svg-icon-md svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Code/Compiling.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="d-none d-sm-inline">Action</span>
                                </template>                                        
                                <b-dropdown-item @click="$router.push(`/project/cutting-he/histori/${val._id}`)">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Home/Book-open.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Detail</span>
                                </b-dropdown-item>                              
                                <b-dropdown-item v-if="val.status == 170" @click="$router.push(`/project/cutting-he/edit/${val._id}`)">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Design/Edit.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Edit</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="val.status == 170" @click="deletePopupData(val)">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Home/Trash.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Delete</span>
                                </b-dropdown-item>
                            </b-dropdown>
                          </td>
                      </tr>
                  </tbody>
                  <tbody v-else>
                      <tr>
                          <td class="p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-sm-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                      </tr>
                      
                      <tr>
                          <td class="p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-sm-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <!--end::Table-->
      </div>
      <!--end::Body-->
      <div class="card-footer">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"></b-pagination>
      </div>

    </div>

    <v-dialog
    v-model="dialogDelete"
    max-width="300">
      <v-card>
        <v-card-title class="headline">Hapus data</v-card-title>

        <v-card-text>
          Apakah anda yakin akan menghapus berita acara <b>{{(dataHapus ? dataHapus.no_ba : '')}}</b> ? data yang dihapus tidak dapat dikembalikan lagi <br/><br/>
          <b-form-checkbox
            id="checkbox-1"
            v-model="permanen"
            name="checkbox-1"
          >
            Hapus Permanen
          </b-form-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <b-button
          variant="link-success"
          class="m-3"
          @click="dialogDelete = false">
            BATAL
          </b-button>

          <b-button
          variant="link-danger"
          class="m-3"
          @click="onDelete">
            HAPUS
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFilter" persistent scrollable max-width="500px">
      <v-card class="px-4 h-100">
        <v-card-title class="headline">Filter Histori</v-card-title>
      
        <perfect-scrollbar
        class="scroll"
        style="max-height: 89%; position: relative;"
        >
          <ValidationObserver ref="formFilter">
          <b-form @submit="onSubmitFilter" v-if="show">
            <v-card-text>
                <div class="mb-7" role="group">
                <label for="input-filter-1">Tanggal Afkir:</label>
                <b-button id="input-filter-1" class="form-control form-control-solid text-left" variant="outline-secondary" @click="modalDate = true">{{ showDate }}</b-button>
                </div>

                <validationProvider name="Hatchery" v-slot="{ errors, valid }">
                    <b-form-group
                        id="input-filter-group-2" 
                        label="Hatchery:" 
                        label-for="input-filter-2"
                        :invalid-feedback="errors[0]"
                        :state="valid">
                        <b-form-select2
                        id="input-filter-2"
                        v-model="filter.hatchery"
                        :options="opsHatchery"
                        required
                        variant="solid"
                        :state="valid"
                        ></b-form-select2>
                    </b-form-group>
                </validationProvider>

                <validationProvider name="Status" v-slot="{ errors, valid }">
                    <b-form-group
                        id="input-filter-group-3" 
                        label="Status:" 
                        label-for="input-filter-3"
                        :invalid-feedback="errors[0]"
                        :state="valid">
                        <b-form-select2
                        id="input-filter-3"
                        v-model="filter.status"
                        :options="opsStatus"
                        required
                        variant="solid"
                        :state="valid"
                        ></b-form-select2>
                    </b-form-group>
                </validationProvider>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <button 
                @click="onSubmitFilter" 
                ref="kt_submit_form_filter"
                class="btn btn-primary font-weight-bold m-3">
                <i class="la la-save"></i>
                Filter
                </button>

                <button
                class="btn btn-clean font-weight-bold m-3"
                type="button"
                @click="onResetFilter()">
                <i class="la la-recycle"></i>
                Reset
                </button>

                <button
                class="btn btn-clean font-weight-bold m-3"
                type="button"
                @click="dialogFilter = false">
                <i class="la la-close"></i>
                Close
                </button>
            </v-card-actions>
          
          </b-form>
          </ValidationObserver>
        </perfect-scrollbar>
      </v-card>
    </v-dialog>

    <v-dialog
        ref="dialogDate"
        v-model="modalDate"
        persistent
        width="290px"
    >
        <v-date-picker
        v-model="dates"
        scrollable
        range
        color="bg-primary"
        header-color="bg-primary"
        >
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="modalDate = false"
        >
            Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="setDate()"
        >
            OK
        </v-btn>
        </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ContentLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CuttingHEService from "@/core/services/api/data/cuttinghe.service";
import PerusahaanService from "@/core/services/api/master/perusahaan.service";
import MasterService from "@/core/services/api/master/master.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "project-cutting-he-histori", 
  components: {
    ContentLoader,
  },  
  data() {
    return {
      show: true,
      modalDate: false,      
      dialogFilter: false,
      dates: [],
      filter: {
          tgl_awal: null,
          tgl_akhir: null,
          hatchery: null,
          status: null
      },
      isLoading: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 8,
      items: [],
      dialogDelete: false,
      dataHapus: null,
      permanen: false,
      opsHatchery: [{
          value: null,
          text: 'Silahkan pilih Hatchery',
      }],
      opsStatus: [{
          value: null,
          text: 'Silahkan pilih Status',
      }],
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    showDate() {
        return (this.filter.tgl_awal || this.filter.tgl_akhir ? `${this.filter.tgl_awal} ~ ${this.filter.tgl_akhir}` : 'Silahkan klik untuk pilih tanggal')
    }
  },
  methods: {
    setDate() {
        if (this.dates.length < 1) {
            ErrorService.message({
                action: 'Peringatan',
                message: 'Silahkan pilih tanggal terlebih dahulu',
            })
            return;
        }
        if (this.dates.length < 2) {
            this.dates.push(this.dates[0])
        }
        this.dates.sort()
        this.filter.tgl_awal = this.dates[0]
        this.filter.tgl_akhir = this.dates[1]
        this.modalDate = false
        this.errorDate = false
    },
    changePage(val){
      if(this.changePage == val) return
      this.isBusy = true
      this.items = []
      this.getData(val)
    },
    resetTable() {
      this.items = []
      this.totalRows = 0
      this.currentPage = 1
    },
    getHatchery() {
      PerusahaanService.getPerusahaanHatchery()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsHatchery = [{
                    value: null,
                    text: "Silahkan pilih Hatchery"
                }]
                return;
            }

            this.opsHatchery = res.data.data
            this.opsHatchery.push({
                value: null,
                text: "Silahkan pilih Hatchery"
            })
        })
        .catch((err) => {
            this.opsHatchery = [{
                value: null,
                text: "Tidak ada Hatchery"
            }]
            ErrorService.status(err)
        })
    },
    getStatus() {
      MasterService.getStatus()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsStatus = [{
                    value: null,
                    text: "Silahkan pilih Status"
                }]
                return;
            }

            this.opsStatus = res.data.data
            this.opsStatus.push({
                value: null,
                text: "Silahkan pilih Status"
            })
        })
        .catch((err) => {
            this.opsStatus = [{
                value: null,
                text: "Tidak ada Status"
            }]
            ErrorService.status(err)
        })
    },
    getData (page = 1, perPage = this.perPage, search = this.filter) {
      CuttingHEService.getHistoriList({page, per_page: perPage, ...search})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.resetTable()
                this.isLoading = false
                return;
            }
            this.items = res.data.data.data
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.total
            this.isLoading = false
        })
        .catch((err) => {
          this.resetTable()
          this.isLoading = false
          return ErrorService.status(err)
        })
    },
    removeSpinner(button) { 
        button.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
        );
    },
    onSubmitFilter(evt) {
        evt.preventDefault()
        this.$refs.formFilter.validate().then(success => {
            if (!success) {
                ErrorService.message({
                    action: 'Peringatan',
                    message: 'Silahkan lengkapi form data terlebih dahulu',
                })
                return;
            }

            // set spinner to submit button
            const submitButton = this.$refs["kt_submit_form_filter"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            // dummy delay
            setTimeout(() => {
                this.dialogFilter = false
                this.getData(1)

                this.removeSpinner(submitButton)
            }, 800);
        })
    },
    onResetFilter () {
        this.filter.tgl_awal = null
        this.filter.tgl_akhir = null
        this.filter.hatchery = null
        this.filter.status = null
        this.dates = []

        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    },
    deletePopupData(val) {
      this.dataHapus = val
      this.dialogDelete = true
    },
    onDelete () {
      if (! this.dataHapus) {
        return
      }
      CuttingHEService.deleteCuttingHE({ _id: this.dataHapus._id, permanen: this.permanen })
        .then((res) => {
            if (res.data.status) { 
              this.getData(this.currentPage)              
            }
            ErrorService.message(res.data)
            this.dialogDelete = false
            this.permanen = false
            this.dataHapus = null
        })
        .catch((err) => {
          this.dialogDelete = false
          this.permanen = false
          this.dataHapus = null
          return ErrorService.status(err)
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Cutting HE" }]);
    if (!this.currentUser.menu.cutting_he) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getStatus()
    this.getHatchery()
    this.getData()
  },
};
</script>